import * as React from "react"
// import { Link } from "gatsby"

import { Container  } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container className="singleCol">
      <h1 className="mb-4">Museum Weapons Policy</h1>
      <p>
      For the safety of our members, guests, and employees, the Museum prohibits the possession of weapons of any kind on its property except for law enforcement officers on duty. Weapons include firearms, explosives, knives, and other objects that staff deem dangerous. Any person who is in possession of a weapon may be denied entry or asked to leave the property. Any person asked to leave who fails to do so immediately shall be guilty of trespass.
      </p>
    </Container>
      
  </Layout>
)

export const Head = () => <Seo title="Museum Weapons Policy" />

export default SecondPage
